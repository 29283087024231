body {
  margin: 0;
  font-family: "Youndi";
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@font-face {
  font-family: "Youndi";
  font-weight: 50;
  src: url("./fonts/fontYouandiModernTR.ttf");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
a {
  text-decoration-line: none;
  color: black;
}
a:hover {
  color: black;
}

/*Full Size Start*/
.loginInfoMenu{
  background: #27404E;
  height: 43px;
  display: flex;
}
.todayStatusContainer {
  display: flex; /* Flexbox를 사용하여 요소들을 수평으로 배치합니다. */
}
.todayStatusBoard{
  color: #d6d6d6;
  font-size: 11px;
  font-family: 'Malgun Gothic', 'dotum', 'verdana', 'arial';
  background-color: black;
  height:50px;
  flex-grow: 1;
}
.yellowFontColor {
  color: yellow; /* 숫자의 색상을 노란색으로 변경합니다. */
}
.menuMoneyLogoImg{
  width: 18px; /* 이미지 크기에 맞게 조정 */
  height: 18px; /* 이미지 크기에 맞게 조정 */
  background-image: url("../public/images/menu/moneyWhite.png");
}
.menuMoneyLogoImg:hover{
  width: 18px; /* 이미지 크기에 맞게 조정 */
  height: 18px; /* 이미지 크기에 맞게 조정 */
  background-image: url("../public/images/menu/moneyYellow.png");
}
.navbar {
  padding: 0; /* 패딩을 없애려면 0으로 설정합니다. */
  margin-top: 0; /* 상단 여백을 없애려면 0으로 설정합니다. */
  display: flex;
  height: 33px;
}
.navbar-collapse {
  display: flex;
  height: 100%; /* 부모(nav)의 높이를 상속하도록 설정합니다. */
}

.adminMenu {
  background: #3c3c3d;
}
.adminMenu ul .nav-item .nav-link {
  font-size: 11px;
  color: #FFFFFF;
  padding-bottom: 7px;
}
.adminMenu ul .nav-item .nav-link:hover{
  color: yellow;
}
.nav-item {
  font-size: 11px;
}
.adminMenu ul .nav-item .nav-link-img {
  font-size: 11px;
  color: #FFFFFF;
  padding-top: 10px;
}

.menuHomeLogoImg{
  width: 18px; /* 이미지 크기에 맞게 조정 */
  height: 18px; /* 이미지 크기에 맞게 조정 */
  background-image: url("../public/images/menu/homeLogoWhite.png");
}
.menuHomeLogoImg:hover{
  width: 18px; /* 이미지 크기에 맞게 조정 */
  height: 18px; /* 이미지 크기에 맞게 조정 */
  background-image: url("../public/images/menu/homeLogoYellow.png");
}
.menuChargeCntLogoImg{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-image: url("../public/images/menu/chargeCntWhite.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 1px;
}
.menuChargeCntLogoImg:hover{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/chargeCntYellow.png");
}
.menuWithdrawLogoImg{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/withdrawWhite.png");
  padding-bottom: 1px;
}
.menuWithdrawLogoImg:hover{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/withdrawYellow.png");
}
.menuHighValueBetLogoImg{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/highValueBetWhite.png");
  padding-bottom: 1px;
}
.menuHighValueBetLogoImg:hover{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/highValueBetYellow.png");
}
.menuWriteLogoImg{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/writeWhite.png");
  padding-bottom: 1px;
}
.menuWriteLogoImg:hover{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/writeYellow.png");
}
.menuCustCenterLogoImg{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/custCenterWhite.png");
  padding-bottom: 1px;
}
.menuCustCenterLogoImg:hover{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/custCenterYellow.png");
}
.menuConsultationLogoImg{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/consultationWhite.png");
  padding-bottom: 1px;
}
.menuConsultationLogoImg:hover{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/consultationYellow.png");
}
.menuStayUserLogoImg{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/stayUserWhite.png");
  padding-bottom: 1px;
}
.menuStayUserLogoImg:hover{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/stayUserYellow.png");
}
.menuLadderLogoImg{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/ladderWhite.png");
  padding-bottom: 1px;
}
.menuLadderLogoImg:hover{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/ladderYellow.png");
}
.menuLadderLogoImg{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/ladderWhite.png");
  padding-bottom: 1px;
}
.menuLadderLogoImg:hover{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/ladderYellow.png");
}
.menuVolumeOnLogoImg{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/volumeOnWhite.png");
  padding-bottom: 1px;
}
.menuVolumeOnLogoImg:hover{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/volumeOnYellow.png");
}
.menuAlarmLogoImg{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/alarmWhite.png");
  padding-bottom: 1px;
}
.menuAlarmLogoImg:hover{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/alarmYellow.png");
}
.menuEndLogoImg{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/endWhite.png");
  padding-bottom: 1px;
}
.menuEndLogoImg:hover{
  width: 20px; /* 이미지 크기에 맞게 조정 */
  height: 20px; /* 이미지 크기에 맞게 조정 */
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/images/menu/endYellow.png");
}

.menuSearch{
  font-size: 11px;
  font-weight: bold;
  color: #FFFFFF;
  padding: 5px;
}
.connected_user_num {
  background: #0094FF;
  padding: 1px 5px 1px 4px;
  border: 2px solid #2E587C;
  border-radius: 5px;
  text-align: center;
  margin-left: 5px;
}
.active{
  font-size: 18px;
  font-weight: bold;
  color: #a319a1
}
.statusBoard {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  height: 80vh;
}
.statusBoard::-webkit-scrollbar {
  display: none;
}
.navbarSub {
  padding: 0; /* 패딩을 없애려면 0으로 설정합니다. */
  margin-top: 0; /* 상단 여백을 없애려면 0으로 설정합니다. */
  display: flex;
  height: 33px;
}
.navbarSub-collapse {
  display: flex;
  height: 100%; /* 부모(nav)의 높이를 상속하도록 설정합니다. */
}
.adminSubMenuContainer {
  display: flex;
  justify-content: flex-start; /* 요소들을 왼쪽부터 배치합니다. */
  gap: 2px; /* 요소들 간의 간격을 설정합니다. */
  /*position: fixed;*/
  margin-left: 220px; /* 시작 위치를 화면 왼쪽으로부터 전체 화면의 10%로 설정합니다. */
  margin-bottom: 20px;
}
.adminSubMenuBoard{
  font-size: 12px;
  color: black;
  font-weight: bold;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}
.adminSubMenuBoard:hover{
  color: #a319a1;
}
.greenBtn {
  background-color: #5dc434;
  border: none;
  color: white;
  font-size: 9px;
  height: 20px;
  width: fit-content;
  margin-right: 10px;
}
/*loginModal*/
.modal_background{
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 1);
  z-index: 100;
}
.modal_box{
  position: absolute;
  top: calc(50vh - 100px); left: calc(50vw - 200px);
  display: flex; justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 400px;
  height: 200px;
}
/*loginModal_end*/
/*login_log*/
.tooltipContent {
  position: relative;
  display: inline-block;
  color: black;
}
.tooltipContent .tooltipText {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 10%;
  left: 100%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipContent:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
/*login_log_end*/
/*help*/
.help_member_collapse {
  font-size: 10px;
}
.help_member_collapse input {
  font-size: 10px;
}
.help_member_collapse select {
  font-size: 10px;
}
.help_member_collapse button{
  font-size: 10px;
}
.help{
  font-size: 14px;
}
.ah {
  font-size: 15px;
}
.homet1 table th {
  font-size: 12px;
}
.homet1 table td {
  font-size: 12px;
}
.calculate .partner {
  background: #FFF3CD
}
.calculate .total {
  background: #D1E7DD;
}
.calculate .borderLeft {
  border-left: 2px solid darkgray;
}

.userStatus button{
  font-size: 13px;
  width: 70px;
  height: 30px;
}
.usert1 {
  margin-top: 5px;
}
.usert1 table td {
  font-size: 14px;
}
.usert1 table th {
  font-size: 14px;
}
.usert1 tr td .nav-link {
  color: black;
  font-weight: bold;
}

.markd1 {
  display: flex;
  font-size: 14px;
}
.markt1 {
  font-size: 14px;
}
.hacklistt1 {
  word-break: normal;
}
.depositt1 tr td .nav-link {
  color: black;
  font-weight: bold;
}
.withdrawt1 tr td .nav-link {
  color: black;
  font-weight: bold;
}
.depositt2 tr td .nav-link {
  color: black;
  font-weight: bold;
}
.withdrawt2 tr td .nav-link {
  color: black;
  font-weight: bold;
}
.depositd1 button{
  font-size: 14px;
}
.depositt1 table th {
  font-size: 14px;
}
.depositt1 table td {
  font-size: 14px;
}
.member_collapse {
  font-size: 12px;
}
.member_collapse input {
  font-size: 14px;
}
.member_collapse select {
  font-size: 13px;
}


.depositt2 table th {
  font-size: 14px;
}
.depositt2 table td {
  font-size: 14px;
}
.withdrawd1 button{
  font-size: 13px;
}
.withdrawt1 table th {
  font-size: 13px;
}
.withdrawt1 table td {
  font-size: 13px;
}
.withdrawt2 table th {
  font-size: 14px;
}
.withdrawt2 table td {
  font-size: 14px;
}
.toast_alert {
  background-color: rgba(255, 127, 80, 0.4);
  border: 1px solid rgba(255, 127, 80, 0.1);
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%);
  height: 40px;
  width: 150px;
  padding: 5px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 20vh;
  left: 20px;
}

.casinot1 table td {
  font-size: 14px;
}
.agentt1 table td {
  font-size: 14px;
}
.agentt1 tr td .nav-link {
  color: black;
  font-weight: bold;
}
.adminId {
  text-align: center;
  font-size: 14px;
}
.adminButton {
  display: flex;
  margin-top :5px;
}
.adminButton button {
  width: 70px;
  font-size: 13px;
}

.bgRequest {
  background-color: #3CB37177;
}
.bgWait {
  background-color: #FFC10777;
}
.bgComplete {
  background-color: #0D6EF277;
}
.bgCancel {
  background-color: #DC354577;
}
/*modal*/
.memberLog_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(150, 150, 150, 0.3);
  z-index: 99999;
}
.log_modal_box {
  position: absolute;
  top: calc(10vh); left: calc(10vw);
  display: flex; justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.member_Log {
  width: 160vh;
  height: 80vh;
  font-size: 15px;
  border: 1px solid black;
  border-radius: 7px;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}
.member_Log .modalOverflow {
  height: 75vh;
}
.content table td{
  font-size: 15px;
}
.member_Log .modalOverflow::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.member_Log_close {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 15px;
}

.adminAdd_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 30;

}
.adminAdd_modal_box {
  position: absolute;
  top: calc(30vh); left: calc(35vw);
  display: flex; justify-content: center;
  align-items: center;
  animation: fadeIn 0.25s ease-in;

}

.adminAdd {
  width: 60vh;
  height: 35vh;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
  border-radius: 5px;
}
.adminAdd .modalOverflow::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.adminAdd_close {
  position: absolute;
  right: 5px;
  bottom: 5px;
  cursor: pointer;
  font-size: 15px;
}


.changePw_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(150, 150, 150, 0.4);
  z-index: 99999;
}
.changePw_modal_box {
  position: absolute;
  top: calc(33vh); left: calc(37vw);
  display: flex; justify-content: center;
  align-items: center;
  animation: fadeIn 0.25s ease-in;
}
.changePw {
  width: 50vh;
  height: 32vh;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
  border-radius: 7px;
}
.changePw .modalOverflow::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.changePw_close {
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-size: 15px;
}


.memberMemo_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(150, 150, 150, 0.3);
  z-index: 99999;
}
.Memo_box {
  position: absolute;
  top: calc(15vh); left: calc(25vw);
  display: flex; justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.member_memo {
  width: 90vh;
  height: 50vh;
  border: 1px solid black;
  border-radius: 7px;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}
.member_memo::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.member_memo_close {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  font-size: 15px;
}
/*modal_end*/
/*partner_modal*/
.partner_modal_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
.partner_modal_box {
  position: absolute;
  top: calc(3vh); left: calc(25vw);
  display: flex; justify-content: center;
  align-items: center;

}
.partner_modal_body {
  width: 100vh;
  height: 92vh;
  border: 1px solid black;
  border-radius: 10px;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}
.partner_modal_body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.partner_modal_body .title {
  font-size: 20px;
  padding: 1%;
}
.partner_modal_body .content {
  font-size: 14px;
}
.partner_modal_body .content .items {
  font-size: 14px;
  height: 35px
}
.partner_modal_body .content button{
  font-size: 14px;
}
.partner_modal_body .content select{
  font-size: 14px;
}
.partner_nodal_body .register {
  position: absolute;
  bottom: 3%;
  font-size: 18px;
}

/*partner_modal_end*/
/*Full Size END*/


@media (max-width: 1280px) {
  .help{
    font-size: 13px;
  }
  .ah span {
    font-size: 11px;
  }
  .homet1 table th {
    font-size: 12px;
  }
  .homet1 table td {
    font-size: 12px;
  }
  .usert1 table td {
    font-size: 12px;
  }
  .markd1 {
    display: block;

  }
  .markt1 {
    font-size: 12px;
  }

  .depositt1 table td {
    font-size: 12px;
  }
  .depositt2 table td {
    font-size: 12px;
  }
  .withdrawt1 table td {
    font-size: 12px;
  }
  .withdrawt2 table td {
    font-size: 12px;
  }
  .casinot1 table td {
    font-size: 12px;
  }
  .agentt1 table td {
    font-size: 12px;
  }
  .adminId {
    text-align: center;
    font-size: 12px;
  }
  .memo tr th{
    font-size: 13px;
  }
  .memo tr td{
    font-size: 13px;
  }

  /*setting*/
  .settingPage tr td {
    font-size: 13px;
  }
  /*setting_end*/
}

/*스포츠 API 설정 시작*/
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; /* 요소 사이의 공간을 균등하게 배치 */
  align-content: space-evenly; /* 요소 행 사이의 공간을 균등하게 배치 */
  flex: 1; /* App 화면이 남은 공간을 채우도록 설정 */
  padding: 20px; /* .item 내부의 간격을 설정 */
}

.item {
  flex: 1;
  min-width: 40%; /* 각 아이템의 최소 너비를 조정하여 균등하게 배치 */
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
}
/*스포츠 API 설정 끝*/

/*1280 Size End*/

@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}
.blink {
  animation: blink-effect 1s step-end infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.selected-leftmenu {
  color: #A319A1; /* 선택된 메뉴의 폰트 색상을 보라색으로 지정 */
  font-size: 16px; /* 선택된 메뉴의 폰트 크기를 16px로 지정 */
  font-weight: bold; /* 선택된 메뉴의 폰트 굵기를 bold로 지정 */
  /* 다른 스타일 속성을 추가할 수 있음 */
}
.selected-leftmenu:hover {
  color: #A319A1;/* 선택된 메뉴에 호버할 때 아무 스타일 변경 없음 */
}

.sm-font {
  font-size: 12px;
}
.sm-select {
  width: 130px;
  height: 20px;
  font-size: 10px;
  padding-left: 5px;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 3px;

}
.sm-input {
  width: 130px;
  height: 20px;
  font-size: 12px;
  margin-top: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}
.sm-date {
  width: 180px;
  height: 20px;
  font-size: 12px;
  margin-top: 0;
}
.sm-center-div {
  display: flex;
  border: lightgray solid 1px;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin-right: 1px;
  font-size: 10px;
  width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
}
.sm-center-div-no-border {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin-right: 1px;
  font-size: 10px;
  width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
}
.sm-center-div-no-border-100 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-right: 1px;
  font-size: 10px;
  width: 100%;
  padding-left: 3px;
  padding-right: 3px;
}
.sm-radio {
  margin-top: 0;
  margin-left: 0;
  margin-right: 1px;
  margin-bottom: 0;
}
.sm-label {
  margin-right: 5px;
}

.common{
  font-size: 9px;
}
.form-select{
  font-size: 9px;
}
.btn{
  font-size: 9px;
}
.form-control{
  font-size: 9px;
}

.commonT table thead tr th{
  font-size: 9px;
  background-color : #E6E6E6;
  border: 1px solid #D7D4CF;
  font-weight: normal; /* 굵기 제거 */
  vertical-align: middle;
}

/*modal_100v_92v start*/
.modal_100v_92v_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
.modal_100v_92v_box {
  position: absolute;
  top: calc(3vh); left: calc(25vw);
  display: flex; justify-content: center;
  align-items: center;

}
.modal_100v_92v_body {
  width: 100vh;
  height: 92vh;
  border: 1px solid black;
  border-radius: 10px;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}
.modal_100v_92v_body table {
  width: 100%;
}
.modal_100v_92v_body th {
  font-size: 11px;
  background-color : #E6E6E6;
  border: 1px solid #D7D4CF;
  vertical-align: middle;
  text-align: center;
  height: 30px;
}
.modal_100v_92v_body td {
  font-size: 11px;
  border: 1px solid #D7D4CF;
  vertical-align: middle;
  height: 30px;
}

.modal_100v_92v_body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.modal_100v_92v_body .title {
  font-size: 11px;
  padding: 1%;
}
.modal_100v_92v_body .content {
  font-size: 11px;
}
.modal_100v_92v_body .content .items {
  font-size: 11px;
  height: 35px
}
.modal_100v_92v_body .content button{
  font-size: 11px;
}
.modal_100v_92v_body .content select{
  font-size: 11px;
}
.modal_100v_92v_body .register {
  position: absolute;
  bottom: 3%;
  font-size: 11px;
}

/*modal_200v_150v start*/
.big_modal_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
.big_modal_box {
  position: absolute;
  top: calc(1vh);
  left: calc(1vw);
  right: calc(1vw);
  bottom: calc(1vw);
  display: flex; justify-content: center;
  align-items: center;

}
.big_modal_body {
  width: 100%;
  height: 92vh;
  border: 1px solid black;
  border-radius: 10px;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  overflow-y: scroll; /* 변경된 부분: 스크롤바를 표시하도록 설정 */
  overflow-x: hidden; /* 가로 스크롤바 감춤 */
}
.big_modal_body table {
  width: 98%;
  height: 200vh;
}
.big_modal_body th {
  font-size: 11px;
  background-color : #E6E6E6;
  border: 1px solid #D7D4CF;
  vertical-align: middle;
  text-align: center;
}
.big_modal_body td {
  font-size: 11px;
  border: 1px solid #D7D4CF;
  vertical-align: middle;
}
.big_modal_body .big_table2 {
  height: 50vh;
}
.big_modal_body::-webkit-scrollbar {
  width: 5px; /* 스크롤바 너비 설정 */
}
.big_modal_body .title {
  font-size: 11px;
  padding: 1%;
}
.big_modal_body .content {
  font-size: 11px;
}
.big_modal_body .content .items {
  font-size: 11px;
  height: 35px
}
.big_modal_body .content button{
  font-size: 11px;
}
.big_modal_body .content select{
  font-size: 11px;
}
.big_modal_body .register {
  position: absolute;
  bottom: 3%;
  font-size: 11px;
}
.big_modal_body .scrollable-content {
  max-height: calc(92vh - 30px); /* 내부 컨텐츠가 모달의 높이보다 클 때 스크롤바 표시 */
  overflow-y: auto; /* 스크롤바를 표시하도록 설정 */
}

.close-modal-button {
  position: fixed;
  top: 20px;
  right: 50px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
  color: #333;
}

.close-modal-button:hover {
  background-color: #eee;
}
/*modal_100v_92v_end*/


/*user info detail_modal*/
.user_detail_modal_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
.user_detail_modal_box {
  position: absolute;
  top: calc(3vh); left: calc(25vw);
  display: flex; justify-content: center;
  align-items: center;

}
.user_detail_modal_body {
  width: 125vh;
  height: 94vh;
  border: 1px solid black;
  border-radius: 10px;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}
.user_detail_modal_body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.user_detail_modal_body .title {
  font-size: 20px;
  padding: 1%;
}
.user_detail_modal_body .content {
  font-size: 14px;
}
.user_detail_modal_body .content .items {
  font-size: 14px;
  height: 35px
}
.user_detail_modal_body .content button{
  font-size: 14px;
}
.user_detail_modal_body .content select{
  font-size: 14px;
}
.user_detail_nodal_body .register {
  position: absolute;
  bottom: 3%;
  font-size: 18px;
}

/*user_detail_modal_end*/


/*member_excel_modal01*/
.member_modal_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
.member_modal_box {
  position: absolute;
  top: calc(10vh); left: calc(5vw);
  display: flex; justify-content: center;
  align-items: center;

}
.mebmer_modal_body {
  width: 50vh;
  height: 42vh;
  border: 1px solid black;
  border-radius: 10px;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}
.member_modal_body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.member_modal_body .title {
  font-size: 20px;
  padding: 1%;
}
.member_modal_body .content {
  font-size: 14px;
}
.member_modal_body .content .items {
  font-size: 14px;
  height: 35px
}
.member_modal_body .content button{
  font-size: 14px;
}
.member_modal_body .content select{
  font-size: 14px;
}
.member_nodal_body .register {
  position: absolute;
  bottom: 3%;
  font-size: 18px;
}

/*member_excel_modal01*/

/*member_excel_modal02*/
.member02_modal_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
.member02_modal_box {
  position: absolute;
  top: calc(10vh); left: calc(5vw);
  display: flex; justify-content: center;
  align-items: center;

}
.mebmer02_modal_body {
  width: 50vh;
  height: 85vh;
  border: 1px solid black;
  border-radius: 10px;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}
.member02_modal_body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.member02_modal_body .title {
  font-size: 20px;
  padding: 1%;
}
.member02_modal_body .content {
  font-size: 14px;
}
.member02_modal_body .content .items {
  font-size: 14px;
  height: 35px
}
.member02_modal_body .content button{
  font-size: 14px;
}
.member02_modal_body .content select{
  font-size: 14px;
}
.member02_nodal_body .register {
  position: absolute;
  bottom: 3%;
  font-size: 18px;
}

/*member_excel_modal02*/

/*member_excel_modal03*/
.member03_modal_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
.member03_modal_box {
  position: absolute;
  top: calc(10vh); left: calc(5vw);
  display: flex; justify-content: center;
  align-items: center;

}
.mebmer03_modal_body {
  width: 60vh;
  height: 45vh;
  border: 1px solid black;
  border-radius: 10px;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}
.member03_modal_body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.member03_modal_body .title {
  font-size: 20px;
  padding: 1%;
}
.member03_modal_body .content {
  font-size: 14px;
}
.member03_modal_body .content .items {
  font-size: 14px;
  height: 35px
}
.member03_modal_body .content button{
  font-size: 14px;
}
.member03_modal_body .content select{
  font-size: 14px;
}
.member03_nodal_body .register {
  position: absolute;
  bottom: 3%;
  font-size: 18px;
}

/*member_excel_modal03*/
/* user_simple_modal start */
.user_simple_modal_background {
  z-index: 1000;
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.5);
}

.user_simple_modal_box {
  z-index: 1001;
  position: absolute;
  top: 0;
  display: flex; justify-content: center;
  align-items: center;
}

.user_simple_modal_body {
  z-index: 1002;
  display: flex;
  margin-top: 100px;
  width: 700px;
  background: transparent;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
  font-size: 9px;
}
.user_simple_modal_body button {
  font-size: 10px;
  font-weight: bold;
}

/*user info combine_modal*/
.user_combine_modal_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
.user_combine_modal_box {
  position: absolute;
  top: calc(2vh); left: calc(1vw);
  display: flex; justify-content: center;
  align-items: center;

}
.user_combine_modal_body {
  width: 200vh;
  height: 95vh;
  border: 1px solid black;
  border-radius: 10px;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}
.user_combine_modal_body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.user_combine_modal_body .title {
  font-size: 20px;
  padding: 1%;
}
.user_combine_modal_body .content {
  font-size: 14px;
}
.user_combine_modal_body .content .items {
  font-size: 14px;
  height: 35px
}
.user_combine_modal_body .content button{
  font-size: 14px;
}
.user_combine_modal_body .content select{
  font-size: 14px;
}
.user_combine_nodal_body .register {
  position: absolute;
  bottom: 3%;
  font-size: 18px;
}

/*user_combine_modal_end*/

/**/
.looking_id_select_box {
  display: inline-block;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 28px;
  border: 2px solid black;
}
.looking_id_show {
  font-weight: bold;
  padding: 3px;
}
/*member_search_modal01*/
.member_search_modal_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
.member_search_modal_box {
  position: absolute;
  top: calc(15vh); left: calc(30vw);
  display: flex; justify-content: center;
  align-items: center;

}
.mebmer_search_modal_body {
  width: 75vh;
  height: 66vh;
  border: 1px solid black;
  border-radius: 10px;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}
.member_search_modal_body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.member_search_modal_body .title {
  font-size: 20px;
  padding: 1%;
}
.member_search_modal_body .content {
  font-size: 14px;
}
.member_search_modal_body .content .items {
  font-size: 14px;
  height: 35px
}
.member_search_modal_body .content button{
  font-size: 14px;
}
.member_search_modal_body .content select{
  font-size: 14px;
}
.member_search_modal_body .register {
  position: absolute;
  bottom: 3%;
  font-size: 18px;
}

/*member_search_modal01*/

/*member_search_select_modal01*/
.member_search_select_modal_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
.member_search_select_modal_box {
  position: absolute;
  top: calc(40vh); left: calc(40vw);
  display: flex; justify-content: center;
  align-items: center;

}
.member_search_select_modal_body {
  width: 40vh;
  height: 25vh;
  border: 1px solid black;
  border-radius: 10px;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}
.member_search_select_modal_body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.member_search_select_modal_body .title {
  font-size: 20px;
  padding: 1%;
}
.member_search_select_modal_body .content {
  font-size: 14px;
}
.member_search_select_modal_body .content .items {
  font-size: 14px;
  height: 35px
}
.member_search_select_modal_body .content button{
  font-size: 14px;
}
.member_search_select_modal_body .content select{
  font-size: 14px;
}
.member_search_select_modal_body .register {
  position: absolute;
  bottom: 3%;
  font-size: 18px;
}

/*member_search_select_modal01*/

/*member_memo_select_modal01*/
.member_memo_select_modal_background {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
.member_memo_select_modal_box {
  position: absolute;
  top: calc(20vh); left: calc(20vw);
  display: flex; justify-content: center;
  align-items: center;

}
.member_memo_select_modal_body {
  width: 110vh;
  height: 62vh;
  border: 1px solid black;
  border-radius: 10px;
  background: white;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}
.member_memo_select_modal_body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.member_memo_select_modal_body .title {
  font-size: 20px;
  padding: 1%;
}
.member_memo_select_modal_body .content {
  font-size: 14px;
}
.member_memo_select_modal_body .content .items {
  font-size: 14px;
  height: 35px
}
.member_memo_select_modal_body .content button{
  font-size: 14px;
}
.member_memo_select_modal_body .content select{
  font-size: 14px;
}
.member_memo_select_modal_body .register {
  position: absolute;
  bottom: 3%;
  font-size: 18px;
}

/*member_memo_select_modal01*/


.commonU table thead tr th{
  font-size: 10px;
  background-color : #E6E6E6;
  border: 1px solid #D7D4CF;
  font-weight: normal; /* 굵기 제거 */
  vertical-align: middle;
}


.looking_id_label.type01, .looking_id_show.type1, .looking_id_show.type01, .looking_id.on.type1 {
  color: red;
  background: #FFFC00;
}

.looking_id_label.typ02, .looking_id_show.type2, .looking_id_show.type02, .looking_id.on.type2 {
  color: #ff00f5;
  background: #00ff72;
}

.looking_id_label.type03, .looking_id_show.type3, .looking_id_show.type03, .looking_id.on.type3 {
  color: #ffffff;
  background: #2c49b1;
}

.looking_id_label.type04, .looking_id_show.type4, .looking_id_show.type04, .looking_id.on.type4 {
  color: #ffffff;
  background: #b72323;
}

.looking_id_label.type05, .looking_id_show.type5, .looking_id_show.type05, .looking_id.on.type5 {
  color: #0e00d0;
  background: #6aeaff;
}

.looking_id_label.type06, .looking_id_show.type6, .looking_id_show.type06, .looking_id.on.type6 {
  color: #fffff4;
  background: #ffa500;
}

.looking_id_label.type07, .looking_id_show.type7, .looking_id_show.type07, .looking_id.on.type7 {
  color: #f9ff00;
  background: #ff0000;
}

.looking_id_label.type08, .looking_id_show.type8, .looking_id_show.type08, .looking_id.on.type8 {
  color: #FFFC00;
  background: #444444;
}

.looking_id_label.type09, .looking_id_show.type9, .looking_id_show.type09, .looking_id.on.type9 {
  color: #41f723;
  background: #a000ff;
}

.looking_id_label.type10, .looking_id_show.type10, .looking_id.on.type10
{
  color: #ff0074;
  background: #00b8ff;
}

.looking_id_label.type11, .looking_id_show.type11, .looking_id.on.type11
{
  color: #ffff00;
  background: #606dbc;
}

.looking_id_label.type12, .looking_id_show.type12, .looking_id.on.type12
{
  color: #00fff5;
  background: #465298;
}

.looking_id_label.type13, .looking_id_show.type13, .looking_id.on.type13
{
  color: #ffff00;
  background: repeating-linear-gradient(45deg, #606dbc, #606dbc 10px, #465298 10px,
  #465298 20px);
}

.looking_id_label.type14, .looking_id_show.type14, .looking_id.on.type14
{
  color: #ff0000;
  text-shadow: 1px 1px #fbff00;
  background: repeating-linear-gradient(45deg, #ffbaba, #ffbaba 10px, #ffff00 10px,
  #ffff00 20px);
}

.looking_id_label.type15, .looking_id_show.type15, .looking_id.on.type15
{
  color: #a74e8b;
  text-shadow: 1px 1px #ffffff;
  background: repeating-linear-gradient(45deg, #00e968, #00d55f 10px, #00ff72 10px, #00ff72 20px);
}

.looking_id_label.type16, .looking_id_show.type16, .looking_id.on.type16
{
  color: #56ff00;
  text-shadow: 1px 1px #000;
  background: repeating-linear-gradient(0deg, #27366c, #27366c 4px, #5373e7 5px, #5373e7 7px);
}

.looking_id_label.type17, .looking_id_show.type17, .looking_id.on.type17
{
  color: #fbff00;
  text-shadow: 1px 1px #000;
  background: repeating-linear-gradient(45deg, #ec2424, #ec2424 6px, #b72323 3px, #b72323 14px);
}

.looking_id_label.type18, .looking_id_show.type18, .looking_id.on.type18
{
  color: #ff0000;
  text-shadow: 1px 1px #fbff00;
  background: repeating-linear-gradient(45deg, #ffa500, #00b7ff 10px, #6aeaff 10px, #ffff00 20px);
}

.looking_id_label.type19, .looking_id_show.type19, .looking_id.on.type19
{
  color: #ffffff;
  text-shadow: 1px 1px #000;
  background: repeating-linear-gradient(45deg, #ff0000, #ffbaba 10px, #ffa500 10px, #ffa500 20px);
}

.looking_id_label.type20, .looking_id_show.type20, .looking_id.on.type20
{
  color: #ffe000;
  text-shadow: 1px 1px #000;
  background: repeating-linear-gradient(45deg, #444444, #a3a3a3 10px, #444444 10px, #444444 20px);
}

.looking_id_label.type21, .looking_id_show.type21, .looking_id.on.type21
{
  color: #ffe000;
  text-shadow: 1px 1px #000;
  background: repeating-linear-gradient(45deg, #63009e, #63009e 10px, #a000ff 10px, #a000ff 20px);
}

.looking_id_label.type22, .looking_id_show.type22, .looking_id.on.type22
{
  color: #ff00d4;
  text-shadow: 1px 1px #fbff00;
  background: repeating-linear-gradient(45deg, #f4ffba, #ffbaba 10px, #00b8ff 10px, #00b8ff 20px);
}

.looking_id_label.type23, .looking_id_show.type23, .looking_id.on.type23
{
  color: #ff0000;
  text-shadow: 1px 1px #fbff00;
  background: url('images/type23.png') #FFFC00;
}

.looking_id_label.type24, .looking_id_show.type24, .looking_id.on.type24
{
  color: #ff0000;
  text-shadow: 1px 1px #fbff00;
  background: repeating-linear-gradient(0deg, #ffd400, #ffd400 3px, #fffc00 3px, #ffff00 6px);
}

.looking_id_label.type25, .looking_id_show.type25, .looking_id.on.type25
{
  color: #fbff00;
  text-shadow: 1px 1px #000;
  background: repeating-linear-gradient(90deg, #7380cd, #7380cd 4px, #424e97 4px, #606dbc 7px);
}




.looking_id_label.type26, .looking_id_show.type26, .looking_id.on.type26 {
  color: #0e00d0;
  text-shadow: 1px 1px #fff;
  background: url('images/type26.png') #6aeaff;
}


.looking_id_label.type27, .looking_id_show.type27, .looking_id.on.type27 {
  color: #ff00f5;
  text-shadow: 1px 1px #fff;
  background: url('images/type27.png') #00ff72;
}

.looking_id_label.type28, .looking_id_show.type28, .looking_id.on.type28 {
  color: #fffff4;
  text-shadow: 1px 1px #000;
  background: url('images/type28.png') #ffa500;
}

.looking_id_label.type29, .looking_id_show.type29, .looking_id.on.type29 {
  color: red;
  text-shadow: 1px 1px #fff;
  background: url('images/type29.png') #FFFC00;
}

.looking_id_label.type30, .looking_id_show.type30, .looking_id.on.type30 {
  color: #FFFC00;
  text-shadow: 1px 1px #000;
  background: url('images/type30.png') #444444;
}


.sm-select2 {
  width: 130px;
  height: 20px;
  font-size: 10px;
  padding-left: 5px;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.adminSubMenuContainer_slide {
  display: flex;
  justify-content: flex-start; /* 요소들을 왼쪽부터 배치합니다. */
  gap: 2px; /* 요소들 간의 간격을 설정합니다. */
  /*position: fixed;*/
  margin-left: 10px; /* 시작 위치를 화면 왼쪽으로부터 전체 화면의 10%로 설정합니다. */
  margin-bottom: 20px;
}

.commonNew{
  font-size: 11px;
  font-family: "Youndi";
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}