.container {
    background-color: #E3ECF1;
}
.sidebar {
    background-color: #E3ECF1;
    border-right: 4px solid #202020;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transition: 0.4s ease;
    color: #202020;
    height: 100%;
    z-index: 99;
}
.button {
    position: relative;
    left: 330px;
    top: 10px;
    width: 40px;
    height: 40px;
    z-index: 99;
    transition: 0.8s ease;
    border: 2px solid #202020;
    border-radius: 40px;
    overflow: hidden;
}
.openBtn {
    width: 100%;
    height: 100%;
}
.content {
    padding: 40px 40px 0 20px;
    position: relative;
    width: 100%;
}
.icon {
    margin: 0;
    color: #202020;
}